export default [
	{ region: "Asia/Kabul", name: "Afghanistan", dial_code: "+93", code: "AF" },
	{ name: "Aland Islands", dial_code: "+358", code: "AX" },
	{ region: "Europe/Tirane", name: "Albania", dial_code: "+355", code: "AL" },
	{
		region: "Africa/Algiers",
		name: "Algeria",
		dial_code: "+213",
		code: "DZ",
	},
	{ name: "AmericanSamoa", dial_code: "+1684", code: "AS" },
	{
		region: "Europe/Andorra",
		name: "Andorra",
		dial_code: "+376",
		code: "AD",
	},
	{ region: "Africa/Luanda", name: "Angola", dial_code: "+244", code: "AO" },
	{
		region: "America/Anguilla",
		name: "Anguilla",
		dial_code: "+1264",
		code: "AI",
	},
	{
		region: "Antarctica/Casey",
		name: "Antarctica",
		dial_code: "+672",
		code: "AQ",
	},
	{ name: "Antigua and Barbuda", dial_code: "+1268", code: "AG" },
	{
		region: "America/Argentina/Buenos_Aires",
		name: "Argentina",
		dial_code: "+54",
		code: "AR",
	},
	{ region: "Asia/Yerevan", name: "Armenia", dial_code: "+374", code: "AM" },
	{ region: "America/Aruba", name: "Aruba", dial_code: "+297", code: "AW" },
	{
		region: "Australia/Lord_Howe",
		name: "Australia",
		dial_code: "+61",
		code: "AU",
	},
	{ region: "Europe/Vienna", name: "Austria", dial_code: "+43", code: "AT" },
	{ region: "Asia/Baku", name: "Azerbaijan", dial_code: "+994", code: "AZ" },
	{
		region: "America/Nassau",
		name: "Bahamas",
		dial_code: "+1242",
		code: "BS",
	},
	{ region: "Asia/Bahrain", name: "Bahrain", dial_code: "+973", code: "BH" },
	{ region: "Asia/Dhaka", name: "Bangladesh", dial_code: "+880", code: "BD" },
	{
		region: "America/Barbados",
		name: "Barbados",
		dial_code: "+1246",
		code: "BB",
	},
	{ region: "Europe/Minsk", name: "Belarus", dial_code: "+375", code: "BY" },
	{
		region: "Europe/Brussels",
		name: "Belgium",
		dial_code: "+32",
		code: "BE",
	},
	{ region: "America/Belize", name: "Belize", dial_code: "+501", code: "BZ" },
	{
		region: "Africa/Porto-Novo",
		name: "Benin",
		dial_code: "+229",
		code: "BJ",
	},
	{
		region: "Atlantic/Bermuda",
		name: "Bermuda",
		dial_code: "+1441",
		code: "BM",
	},
	{ region: "Asia/Thimphu", name: "Bhutan", dial_code: "+975", code: "BT" },
	{ name: "Bolivia, Plurinational State of", dial_code: "+591", code: "BO" },
	{ name: "Bosnia and Herzegovina", dial_code: "+387", code: "BA" },
	{
		region: "Africa/Gaborone",
		name: "Botswana",
		dial_code: "+267",
		code: "BW",
	},
	{ region: "America/Noronha", name: "Brazil", dial_code: "+55", code: "BR" },
	{
		region: "Indian/Chagos",
		name: "British Indian Ocean Territory",
		dial_code: "+246",
		code: "IO",
	},
	{ name: "Brunei Darussalam", dial_code: "+673", code: "BN" },
	{ region: "Europe/Sofia", name: "Bulgaria", dial_code: "+359", code: "BG" },
	{
		region: "Africa/Ouagadougou",
		name: "Burkina Faso",
		dial_code: "+226",
		code: "BF",
	},
	{
		region: "Africa/Bujumbura",
		name: "Burundi",
		dial_code: "+257",
		code: "BI",
	},
	{
		region: "Asia/Phnom_Penh",
		name: "Cambodia",
		dial_code: "+855",
		code: "KH",
	},
	{
		region: "Africa/Douala",
		name: "Cameroon",
		dial_code: "+237",
		code: "CM",
	},
	{ region: "America/St_Johns", name: "Canada", dial_code: "+1", code: "CA" },
	{
		region: "Atlantic/Cape_Verde",
		name: "Cape Verde",
		dial_code: "+238",
		code: "CV",
	},
	{
		region: "America/Cayman",
		name: "Cayman Islands",
		dial_code: "+ 345",
		code: "KY",
	},
	{ name: "Central African Republic", dial_code: "+236", code: "CF" },
	{ region: "Africa/Ndjamena", name: "Chad", dial_code: "+235", code: "TD" },
	{ region: "America/Santiago", name: "Chile", dial_code: "+56", code: "CL" },
	{ region: "Asia/Shanghai", name: "China", dial_code: "+86", code: "CN" },
	{
		region: "Indian/Christmas",
		name: "Christmas Island",
		dial_code: "+61",
		code: "CX",
	},
	{
		region: "Indian/Cocos",
		name: "Cocos (Keeling) Islands",
		dial_code: "+61",
		code: "CC",
	},
	{
		region: "America/Bogota",
		name: "Colombia",
		dial_code: "+57",
		code: "CO",
	},
	{ region: "Indian/Comoro", name: "Comoros", dial_code: "+269", code: "KM" },
	{ name: "Congo", dial_code: "+242", code: "CG" },
	{
		name: "Congo, The Democratic Republic of the Congo",
		dial_code: "+243",
		code: "CD",
	},
	{
		region: "Pacific/Rarotonga",
		name: "Cook Islands",
		dial_code: "+682",
		code: "CK",
	},
	{
		region: "America/Costa_Rica",
		name: "Costa Rica",
		dial_code: "+506",
		code: "CR",
	},
	{ name: "Cote d'Ivoire", dial_code: "+225", code: "CI" },
	{ region: "Europe/Zagreb", name: "Croatia", dial_code: "+385", code: "HR" },
	{ region: "America/Havana", name: "Cuba", dial_code: "+53", code: "CU" },
	{ region: "Asia/Nicosia", name: "Cyprus", dial_code: "+357", code: "CY" },
	{
		region: "Europe/Prague",
		name: "Czech Republic",
		dial_code: "+420",
		code: "CZ",
	},
	{
		region: "Europe/Copenhagen",
		name: "Denmark",
		dial_code: "+45",
		code: "DK",
	},
	{
		region: "Africa/Djibouti",
		name: "Djibouti",
		dial_code: "+253",
		code: "DJ",
	},
	{
		region: "America/Dominica",
		name: "Dominica",
		dial_code: "+1767",
		code: "DM",
	},
	{
		region: "America/Santo_Domingo",
		name: "Dominican Republic",
		dial_code: "+1849",
		code: "DO",
	},
	{
		region: "America/Guayaquil",
		name: "Ecuador",
		dial_code: "+593",
		code: "EC",
	},
	{ region: "Africa/Cairo", name: "Egypt", dial_code: "+20", code: "EG" },
	{
		region: "America/El_Salvador",
		name: "El Salvador",
		dial_code: "+503",
		code: "SV",
	},
	{
		region: "Africa/Malabo",
		name: "Equatorial Guinea",
		dial_code: "+240",
		code: "GQ",
	},
	{ region: "Africa/Asmara", name: "Eritrea", dial_code: "+291", code: "ER" },
	{
		region: "Europe/Tallinn",
		name: "Estonia",
		dial_code: "+372",
		code: "EE",
	},
	{
		region: "Africa/Addis_Ababa",
		name: "Ethiopia",
		dial_code: "+251",
		code: "ET",
	},
	{ name: "Falkland Islands (Malvinas)", dial_code: "+500", code: "FK" },
	{
		region: "Atlantic/Faroe",
		name: "Faroe Islands",
		dial_code: "+298",
		code: "FO",
	},
	{ region: "Pacific/Fiji", name: "Fiji", dial_code: "+679", code: "FJ" },
	{
		region: "Europe/Helsinki",
		name: "Finland",
		dial_code: "+358",
		code: "FI",
	},
	{ region: "Europe/Paris", name: "France", dial_code: "+33", code: "FR" },
	{
		region: "America/Cayenne",
		name: "French Guiana",
		dial_code: "+594",
		code: "GF",
	},
	{
		region: "Pacific/Tahiti",
		name: "French Polynesia",
		dial_code: "+689",
		code: "PF",
	},
	{
		region: "Africa/Libreville",
		name: "Gabon",
		dial_code: "+241",
		code: "GA",
	},
	{ region: "Africa/Banjul", name: "Gambia", dial_code: "+220", code: "GM" },
	{ region: "Asia/Tbilisi", name: "Georgia", dial_code: "+995", code: "GE" },
	{ region: "Europe/Berlin", name: "Germany", dial_code: "+49", code: "DE" },
	{ region: "Africa/Accra", name: "Ghana", dial_code: "+233", code: "GH" },
	{
		region: "Europe/Gibraltar",
		name: "Gibraltar",
		dial_code: "+350",
		code: "GI",
	},
	{ region: "Europe/Athens", name: "Greece", dial_code: "+30", code: "GR" },
	{
		region: "America/Nuuk",
		name: "Greenland",
		dial_code: "+299",
		code: "GL",
	},
	{
		region: "America/Grenada",
		name: "Grenada",
		dial_code: "+1473",
		code: "GD",
	},
	{
		region: "America/Guadeloupe",
		name: "Guadeloupe",
		dial_code: "+590",
		code: "GP",
	},
	{ region: "Pacific/Guam", name: "Guam", dial_code: "+1671", code: "GU" },
	{
		region: "America/Guatemala",
		name: "Guatemala",
		dial_code: "+502",
		code: "GT",
	},
	{ region: "Africa/Conakry", name: "Guinea", dial_code: "+224", code: "GN" },
	{
		region: "Africa/Bissau",
		name: "Guinea-Bissau",
		dial_code: "+245",
		code: "GW",
	},
	{ region: "America/Guyana", name: "Guyana", dial_code: "+595", code: "GY" },
	{
		region: "America/Port-au-Prince",
		name: "Haiti",
		dial_code: "+509",
		code: "HT",
	},
	{ name: "Holy See (Vatican City State)", dial_code: "+379", code: "VA" },
	{
		region: "America/Tegucigalpa",
		name: "Honduras",
		dial_code: "+504",
		code: "HN",
	},
	{
		region: "Asia/Hong_Kong",
		name: "Hong Kong",
		dial_code: "+852",
		code: "HK",
	},
	{
		region: "Europe/Budapest",
		name: "Hungary",
		dial_code: "+36",
		code: "HU",
	},
	{
		region: "Atlantic/Reykjavik",
		name: "Iceland",
		dial_code: "+354",
		code: "IS",
	},
	{ region: "Asia/Kolkata", name: "India", dial_code: "+91", code: "IN" },
	{ region: "Asia/Jakarta", name: "Indonesia", dial_code: "+62", code: "ID" },
	{
		name: "Iran, Islamic Republic of Persian Gulf",
		dial_code: "+98",
		code: "IR",
	},
	{ region: "Asia/Baghdad", name: "Iraq", dial_code: "+964", code: "IQ" },
	{ region: "Europe/Dublin", name: "Ireland", dial_code: "+353", code: "IE" },
	{ region: "Asia/Jerusalem", name: "Israel", dial_code: "+972", code: "IL" },
	{ region: "Europe/Rome", name: "Italy", dial_code: "+39", code: "IT" },
	{
		region: "America/Jamaica",
		name: "Jamaica",
		dial_code: "+1876",
		code: "JM",
	},
	{ region: "Asia/Tokyo", name: "Japan", dial_code: "+81", code: "JP" },
	{ region: "Asia/Amman", name: "Jordan", dial_code: "+962", code: "JO" },
	{ region: "Asia/Almaty", name: "Kazakhstan", dial_code: "+77", code: "KZ" },
	{ region: "Africa/Nairobi", name: "Kenya", dial_code: "+254", code: "KE" },
	{
		region: "Pacific/Tarawa",
		name: "Kiribati",
		dial_code: "+686",
		code: "KI",
	},
	{
		name: "Korea, Democratic People's Republic of Korea",
		dial_code: "+850",
		code: "KP",
	},
	{ name: "Korea, Republic of South Korea", dial_code: "+82", code: "KR" },
	{ region: "Asia/Kuwait", name: "Kuwait", dial_code: "+965", code: "KW" },
	{
		region: "Asia/Bishkek",
		name: "Kyrgyzstan",
		dial_code: "+996",
		code: "KG",
	},
	{ region: "Asia/Vientiane", name: "Laos", dial_code: "+856", code: "LA" },
	{ region: "Europe/Riga", name: "Latvia", dial_code: "+371", code: "LV" },
	{ region: "Asia/Beirut", name: "Lebanon", dial_code: "+961", code: "LB" },
	{ region: "Africa/Maseru", name: "Lesotho", dial_code: "+266", code: "LS" },
	{
		region: "Africa/Monrovia",
		name: "Liberia",
		dial_code: "+231",
		code: "LR",
	},
	{ name: "Libyan Arab Jamahiriya", dial_code: "+218", code: "LY" },
	{
		region: "Europe/Vaduz",
		name: "Liechtenstein",
		dial_code: "+423",
		code: "LI",
	},
	{
		region: "Europe/Vilnius",
		name: "Lithuania",
		dial_code: "+370",
		code: "LT",
	},
	{
		region: "Europe/Luxembourg",
		name: "Luxembourg",
		dial_code: "+352",
		code: "LU",
	},
	{ name: "Macao", dial_code: "+853", code: "MO" },
	{ name: "Macedonia", dial_code: "+389", code: "MK" },
	{
		region: "Indian/Antananarivo",
		name: "Madagascar",
		dial_code: "+261",
		code: "MG",
	},
	{
		region: "Africa/Blantyre",
		name: "Malawi",
		dial_code: "+265",
		code: "MW",
	},
	{
		region: "Asia/Kuala_Lumpur",
		name: "Malaysia",
		dial_code: "+60",
		code: "MY",
	},
	{
		region: "Indian/Maldives",
		name: "Maldives",
		dial_code: "+960",
		code: "MV",
	},
	{ region: "Africa/Bamako", name: "Mali", dial_code: "+223", code: "ML" },
	{ region: "Europe/Malta", name: "Malta", dial_code: "+356", code: "MT" },
	{
		region: "Pacific/Majuro",
		name: "Marshall Islands",
		dial_code: "+692",
		code: "MH",
	},
	{
		region: "America/Martinique",
		name: "Martinique",
		dial_code: "+596",
		code: "MQ",
	},
	{
		region: "Africa/Nouakchott",
		name: "Mauritania",
		dial_code: "+222",
		code: "MR",
	},
	{
		region: "Indian/Mauritius",
		name: "Mauritius",
		dial_code: "+230",
		code: "MU",
	},
	{
		region: "Indian/Mayotte",
		name: "Mayotte",
		dial_code: "+262",
		code: "YT",
	},
	{
		region: "America/Mexico_City",
		name: "Mexico",
		dial_code: "+52",
		code: "MX",
	},
	{
		name: "Micronesia, Federated States of Micronesia",
		dial_code: "+691",
		code: "FM",
	},
	{
		region: "Europe/Chisinau",
		name: "Moldova",
		dial_code: "+373",
		code: "MD",
	},
	{ region: "Europe/Monaco", name: "Monaco", dial_code: "+377", code: "MC" },
	{
		region: "Asia/Ulaanbaatar",
		name: "Mongolia",
		dial_code: "+976",
		code: "MN",
	},
	{
		region: "Europe/Podgorica",
		name: "Montenegro",
		dial_code: "+382",
		code: "ME",
	},
	{
		region: "America/Montserrat",
		name: "Montserrat",
		dial_code: "+1664",
		code: "MS",
	},
	{
		region: "Africa/Casablanca",
		name: "Morocco",
		dial_code: "+212",
		code: "MA",
	},
	{
		region: "Africa/Maputo",
		name: "Mozambique",
		dial_code: "+258",
		code: "MZ",
	},
	{ name: "Myanmar", dial_code: "+95", code: "MM" },
	{
		region: "Africa/Windhoek",
		name: "Namibia",
		dial_code: "+264",
		code: "NA",
	},
	{ region: "Pacific/Nauru", name: "Nauru", dial_code: "+674", code: "NR" },
	{ region: "Asia/Kathmandu", name: "Nepal", dial_code: "+977", code: "NP" },
	{
		region: "Europe/Amsterdam",
		name: "Netherlands",
		dial_code: "+31",
		code: "NL",
	},
	{ name: "Netherlands Antilles", dial_code: "+599", code: "AN" },
	{
		region: "Pacific/Noumea",
		name: "New Caledonia",
		dial_code: "+687",
		code: "NC",
	},
	{
		region: "Pacific/Auckland",
		name: "New Zealand",
		dial_code: "+64",
		code: "NZ",
	},
	{
		region: "America/Managua",
		name: "Nicaragua",
		dial_code: "+505",
		code: "NI",
	},
	{ region: "Africa/Niamey", name: "Niger", dial_code: "+227", code: "NE" },
	{ region: "Africa/Lagos", name: "Nigeria", dial_code: "+234", code: "NG" },
	{ region: "Pacific/Niue", name: "Niue", dial_code: "+683", code: "NU" },
	{
		region: "Pacific/Norfolk",
		name: "Norfolk Island",
		dial_code: "+672",
		code: "NF",
	},
	{
		region: "Pacific/Saipan",
		name: "Northern Mariana Islands",
		dial_code: "+1670",
		code: "MP",
	},
	{ region: "Europe/Oslo", name: "Norway", dial_code: "+47", code: "NO" },
	{ region: "Asia/Muscat", name: "Oman", dial_code: "+968", code: "OM" },
	{ region: "Asia/Karachi", name: "Pakistan", dial_code: "+92", code: "PK" },
	{ region: "Pacific/Palau", name: "Palau", dial_code: "+680", code: "PW" },
	{ name: "Palestinian Territory, Occupied", dial_code: "+970", code: "PS" },
	{ region: "America/Panama", name: "Panama", dial_code: "+507", code: "PA" },
	{
		region: "Pacific/Port_Moresby",
		name: "Papua New Guinea",
		dial_code: "+675",
		code: "PG",
	},
	{
		region: "America/Asuncion",
		name: "Paraguay",
		dial_code: "+595",
		code: "PY",
	},
	{ region: "America/Lima", name: "Peru", dial_code: "+51", code: "PE" },
	{
		region: "Asia/Manila",
		name: "Philippines",
		dial_code: "+63",
		code: "PH",
	},
	{
		region: "Pacific/Pitcairn",
		name: "Pitcairn",
		dial_code: "+872",
		code: "PN",
	},
	{ region: "Europe/Warsaw", name: "Poland", dial_code: "+48", code: "PL" },
	{
		region: "Europe/Lisbon",
		name: "Portugal",
		dial_code: "+351",
		code: "PT",
	},
	{
		region: "America/Puerto_Rico",
		name: "Puerto Rico",
		dial_code: "+1939",
		code: "PR",
	},
	{ region: "Asia/Qatar", name: "Qatar", dial_code: "+974", code: "QA" },
	{
		region: "Europe/Bucharest",
		name: "Romania",
		dial_code: "+40",
		code: "RO",
	},
	{
		region: "Europe/Kaliningrad",
		name: "Russia",
		dial_code: "+7",
		code: "RU",
	},
	{ region: "Africa/Kigali", name: "Rwanda", dial_code: "+250", code: "RW" },
	{ name: "Reunion", dial_code: "+262", code: "RE" },
	{ name: "Saint Barthelemy", dial_code: "+590", code: "BL" },
	{
		name: "Saint Helena, Ascension and Tristan Da Cunha",
		dial_code: "+290",
		code: "SH",
	},
	{ name: "Saint Kitts and Nevis", dial_code: "+1869", code: "KN" },
	{ name: "Saint Lucia", dial_code: "+1758", code: "LC" },
	{ name: "Saint Martin", dial_code: "+590", code: "MF" },
	{ name: "Saint Pierre and Miquelon", dial_code: "+508", code: "PM" },
	{
		name: "Saint Vincent and the Grenadines",
		dial_code: "+1784",
		code: "VC",
	},
	{ name: "Samoa", dial_code: "+685", code: "WS" },
	{
		region: "Europe/San_Marino",
		name: "San Marino",
		dial_code: "+378",
		code: "SM",
	},
	{ name: "Sao Tome and Principe", dial_code: "+239", code: "ST" },
	{
		region: "Asia/Riyadh",
		name: "Saudi Arabia",
		dial_code: "+966",
		code: "SA",
	},
	{ region: "Africa/Dakar", name: "Senegal", dial_code: "+221", code: "SN" },
	{
		region: "Europe/Belgrade",
		name: "Serbia",
		dial_code: "+381",
		code: "RS",
	},
	{
		region: "Indian/Mahe",
		name: "Seychelles",
		dial_code: "+248",
		code: "SC",
	},
	{
		region: "Africa/Freetown",
		name: "Sierra Leone",
		dial_code: "+232",
		code: "SL",
	},
	{
		region: "Asia/Singapore",
		name: "Singapore",
		dial_code: "+65",
		code: "SG",
	},
	{
		region: "Europe/Bratislava",
		name: "Slovakia",
		dial_code: "+421",
		code: "SK",
	},
	{
		region: "Europe/Ljubljana",
		name: "Slovenia",
		dial_code: "+386",
		code: "SI",
	},
	{
		region: "Pacific/Guadalcanal",
		name: "Solomon Islands",
		dial_code: "+677",
		code: "SB",
	},
	{
		region: "Africa/Mogadishu",
		name: "Somalia",
		dial_code: "+252",
		code: "SO",
	},
	{
		region: "Africa/Johannesburg",
		name: "South Africa",
		dial_code: "+27",
		code: "ZA",
	},
	{
		region: "Africa/Juba",
		name: "South Sudan",
		dial_code: "+211",
		code: "SS",
	},
	{
		name: "South Georgia and the South Sandwich Islands",
		dial_code: "+500",
		code: "GS",
	},
	{ region: "Europe/Madrid", name: "Spain", dial_code: "+34", code: "ES" },
	{ region: "Asia/Colombo", name: "Sri Lanka", dial_code: "+94", code: "LK" },
	{ region: "Africa/Khartoum", name: "Sudan", dial_code: "+249", code: "SD" },
	{
		region: "America/Paramaribo",
		name: "Suriname",
		dial_code: "+597",
		code: "SR",
	},
	{ name: "Svalbard and Jan Mayen", dial_code: "+47", code: "SJ" },
	{ name: "Swaziland", dial_code: "+268", code: "SZ" },
	{
		region: "Europe/Stockholm",
		name: "Sweden",
		dial_code: "+46",
		code: "SE",
	},
	{
		region: "Europe/Zurich",
		name: "Switzerland",
		dial_code: "+41",
		code: "CH",
	},
	{ name: "Syrian Arab Republic", dial_code: "+963", code: "SY" },
	{ region: "Asia/Taipei", name: "Taiwan", dial_code: "+886", code: "TW" },
	{
		region: "Asia/Dushanbe",
		name: "Tajikistan",
		dial_code: "+992",
		code: "TJ",
	},
	{
		name: "Tanzania, United Republic of Tanzania",
		dial_code: "+255",
		code: "TZ",
	},
	{ region: "Asia/Bangkok", name: "Thailand", dial_code: "+66", code: "TH" },
	{ name: "Timor-Leste", dial_code: "+670", code: "TL" },
	{ region: "Africa/Lome", name: "Togo", dial_code: "+228", code: "TG" },
	{
		region: "Pacific/Fakaofo",
		name: "Tokelau",
		dial_code: "+690",
		code: "TK",
	},
	{
		region: "Pacific/Tongatapu",
		name: "Tonga",
		dial_code: "+676",
		code: "TO",
	},
	{ name: "Trinidad and Tobago", dial_code: "+1868", code: "TT" },
	{ region: "Africa/Tunis", name: "Tunisia", dial_code: "+216", code: "TN" },
	{ region: "Europe/Istanbul", name: "Turkey", dial_code: "+90", code: "TR" },
	{
		region: "Asia/Ashgabat",
		name: "Turkmenistan",
		dial_code: "+993",
		code: "TM",
	},
	{ name: "Turks and Caicos Islands", dial_code: "+1649", code: "TC" },
	{
		region: "Pacific/Funafuti",
		name: "Tuvalu",
		dial_code: "+688",
		code: "TV",
	},
	{ region: "Africa/Kampala", name: "Uganda", dial_code: "+256", code: "UG" },
	{ region: "Europe/Kiev", name: "Ukraine", dial_code: "+380", code: "UA" },
	{
		region: "Asia/Dubai",
		name: "United Arab Emirates",
		dial_code: "+971",
		code: "AE",
	},
	{
		name: "United Kingdom",
		dial_code: "+44",
		code: "GB",
		region: "Europe/London",
	},
	{
		region: "America/New_York",
		name: "United States",
		dial_code: "+1",
		code: "US",
	},
	{
		region: "America/Montevideo",
		name: "Uruguay",
		dial_code: "+598",
		code: "UY",
	},
	{
		region: "Asia/Samarkand",
		name: "Uzbekistan",
		dial_code: "+998",
		code: "UZ",
	},
	{ region: "Pacific/Efate", name: "Vanuatu", dial_code: "+678", code: "VU" },
	{
		name: "Venezuela, Bolivarian Republic of Venezuela",
		dial_code: "+58",
		code: "VE",
	},
	{
		region: "Asia/Ho_Chi_Minh",
		name: "Vietnam",
		dial_code: "+84",
		code: "VN",
	},
	{ name: "Virgin Islands, British", dial_code: "+1284", code: "VG" },
	{ name: "Virgin Islands, U.S.", dial_code: "+1340", code: "VI" },
	{ name: "Wallis and Futuna", dial_code: "+681", code: "WF" },
	{ region: "Asia/Aden", name: "Yemen", dial_code: "+967", code: "YE" },
	{ region: "Africa/Lusaka", name: "Zambia", dial_code: "+260", code: "ZM" },
	{
		region: "Africa/Harare",
		name: "Zimbabwe",
		dial_code: "+263",
		code: "ZW",
	},
];
