export default [
	require(`../../assets/walks/1.jpg`),
	require(`../../assets/walks/2.jpg`),
	require(`../../assets/walks/3.jpg`),
	require(`../../assets/walks/5.jpg`),
	require(`../../assets/walks/6.jpg`),
	require(`../../assets/walks/7.jpg`),
	require(`../../assets/walks/8.jpg`),
	require(`../../assets/walks/9.jpg`),
];
