export default [
	{
		text: "General Enquiry",
		value: "general",
	},
	{
		text: "Enquiring About a Private Tour",
		value: "private-tour",
	},
	{
		text: "Enquiring About an International Tour",
		value: "international-tour",
	},
	{
		text: "Enquiring About Other Tours",
		value: "other-tours",
	},
	{
		text: "Reporting A Problem",
		value: "report-problem",
	},
];
